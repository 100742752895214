import { DirektcenterQuickVideo } from 'Types';
import QuickVideoEditor from './QuickVideoEditor';

const QuickVideoEditorContainer = () => {
  return <QuickVideoEditor />;
};

QuickVideoEditorContainer.type = 'quick-video-editor';

const initialData: DirektcenterQuickVideo = {
  type: 'quick-video',
  videoFile: '',
  posterImageFile: '',
  caption: '',
};

QuickVideoEditorContainer.initialData = initialData;

export default QuickVideoEditorContainer;
