import { DirektcenterQuickVideo } from 'Types';

import s from './AttachmentQuickVideo.module.scss';
import cn from 'classnames';

type AttachmentQuickVideoProps = {
  attachment: DirektcenterQuickVideo;
  pinned: boolean;
};

const AttachmentQuickVideo = ({
  attachment,
  pinned,
}: AttachmentQuickVideoProps) => {
  return (
    <figure className={cn(s.root, { [s.pinned]: pinned })}>
      <video
        className={s.video}
        width="100%"
        height="auto"
        autoPlay={false}
        playsInline={true}
        controls={true}
        poster={`${process.env.REACT_APP_QUICK_VIDEO_BASE_URL}/${attachment.posterImageFile}`}
      >
        <source
          src={`${process.env.REACT_APP_QUICK_VIDEO_BASE_URL}/${attachment.videoFile}`}
          type="video/mp4"
        />
      </video>
      {attachment.caption && (
        <figcaption data-testid="caption" className={s.figCaption}>
          {attachment.caption}
        </figcaption>
      )}
    </figure>
  );
};

AttachmentQuickVideo.validate = (attachment: DirektcenterQuickVideo) =>
  Boolean(attachment.type === AttachmentQuickVideo.type) &&
  Boolean(attachment.videoFile);

AttachmentQuickVideo.type = 'quick-video';

export default AttachmentQuickVideo;
