import axios, { AxiosProgressEvent } from 'axios';
import { NotificationMessageType } from 'contexts/NotificationContext';
import { ReactNode } from 'react';
import api from 'services/api';
import { trackEvent } from './statistics';

export const trackQuickVideoUploadEvent = (
  eventName: string,
  contentId: string,
  eventValues: { [key: string]: number | boolean | string } = {}
) => {
  trackEvent({
    name: `quick-video-upload-${eventName}`,
    content: { id: contentId, type: 'uploadingDirektcenterQuickVideo' },
    eventValues,
  });
};

const uploadVideo = async (
  file: File,
  flashNotification: (message: NotificationMessageType) => void,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
  abortController: AbortController
) => {
  const flashError = (message: ReactNode) => {
    flashNotification({
      type: 'error',
      message,
    });
  };

  const contentType = file.type;

  if (abortController.signal.aborted) {
    trackQuickVideoUploadEvent('cancelled-by-user', file.name, {
      error: 'Before get video upload url',
    });
    return;
  }

  const response = await api.getVideoUploadUrl(contentType);

  if (response.status === 'fail') {
    flashError('Något gick fel under videouppladdningen. Vänligen testa igen');
    trackQuickVideoUploadEvent('getVideoUploadUrl-fail', file.name);
    return;
  }

  if (response.status === 'error') {
    flashError('Någonting gick fel vid videouppladdning. Vänligen testa igen');
    trackQuickVideoUploadEvent('getVideoUploadUrl-error', file.name);
    return;
  }

  const data = response.data;

  try {
    const result = await axios.put(data.uploadUrl, file, {
      signal: abortController.signal,
      headers: {
        'Content-Type': contentType,
      },
      onUploadProgress,
    });

    if (result.data.message === 'canceled') {
      trackQuickVideoUploadEvent('cancelled-by-user', file.name, {
        error: 'Upload to bucket',
      });

      return;
    }
  } catch (error) {
    trackQuickVideoUploadEvent('upload-to-bucket-error', file.name, {
      error: JSON.stringify(error),
    });
    flashError('Oväntat fel vid videouppladdning. Vänligen försök igen');
    return;
  }

  return data.filePath;
};

export default uploadVideo;
