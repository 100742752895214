import React, { useRef, useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PendingPostList from './PendingPostList';
import Header from './Header';
import useScrollTopListener from 'hooks/useScrollTopListener';
import useThrottledCallback from 'hooks/useThrottledCallback';
import Loader from 'components/Loader/Loader';
import NoItemsNotice from 'components/NoItemsNotice/NoItemsNotice';
import LoadMoreButton from 'components/LoadMoreButton/LoadMoreButton';
import s from './PendingPostStream.module.scss';
import cn from 'classnames';
import { NONE } from 'utils/pendingPostInteractiveStates';

const postsReducerActions = {
  ADD_STATE_TO_POST: 'ADD_STATE_TO_POST',
  REMOVE_STATE_FROM_POST: 'REMOVE_STATE_FROM_POST',
};

const reducer = (state, action) => {
  if (!action.type) {
    throw new Error('No action type specified!');
  }

  const { ADD_STATE_TO_POST, REMOVE_STATE_FROM_POST } = postsReducerActions;

  switch (action.type) {
    case ADD_STATE_TO_POST:
      return {
        pendingPostsInteractiveState: state.pendingPostsInteractiveState
          .filter(
            (state) => state.pendingPostId !== action.payload.pendingPostId
          )
          .concat(action.payload),
      };

    case REMOVE_STATE_FROM_POST:
      return {
        pendingPostsInteractiveState: state.pendingPostsInteractiveState.filter(
          (state) => state.pendingPostId !== action.payload.pendingPostId
        ),
      };

    default:
      throw new Error('Action not recognized: ', action.type);
  }
};

const PendingPostStream = ({
  paginatedStream,
  streamId,
  isActive,
  isVisitorPostingEnabled,
  pauseManager,
}) => {
  const scrollListEl = useRef(null);

  const [state, dispatcher] = useReducer(reducer, {
    pendingPostsInteractiveState: [],
  });

  const [pendingPostVisible, setPendingPostVisible] = useState(false);

  useEffect(() => {
    if (state.pendingPostsInteractiveState.length === 0) {
      pauseManager.stoppedManuallyInteractingWithPendingPost();
    } else {
      pauseManager.startedManuallyInteractingWithPendingPost();
    }
  }, [pauseManager, state.pendingPostsInteractiveState.length]);

  const handleMouseMove = useThrottledCallback(
    pauseManager.startCursorInteraction,
    500,
    false
  );

  useScrollTopListener(
    (scrollTop) => {
      scrollTop > 0
        ? pauseManager.scrolledDown()
        : pauseManager.scrolledToTop();
    },
    scrollListEl.current,
    50
  );

  const setInteractiveState = (pendingPostId, pendingPostInteractiveState) => {
    if (pendingPostInteractiveState === NONE) {
      dispatcher({
        type: postsReducerActions.REMOVE_STATE_FROM_POST,
        payload: {
          pendingPostId,
        },
      });
    } else {
      dispatcher({
        type: postsReducerActions.ADD_STATE_TO_POST,
        payload: {
          pendingPostId,
          pendingPostInteractiveState,
        },
      });
    }
  };

  const scrollToTop = () => {
    scrollListEl.current.scrollTop = 0;
  };

  const showLoadMore =
    paginatedStream.hasMorePosts && !paginatedStream.loadingMorePosts;

  const renderContent = () => {
    if (paginatedStream.loadingInitialPosts) {
      return <Loader />;
    }

    if (!paginatedStream.posts.length) {
      return <NoItemsNotice>Inga besökarinlägg</NoItemsNotice>;
    }

    return (
      <React.Fragment>
        <PendingPostList
          posts={paginatedStream.posts}
          streamHasGuests={paginatedStream.hasGuests}
          isActive={isActive}
          streamId={streamId}
          pendingPostsInteractiveState={state.pendingPostsInteractiveState}
          setInteractiveState={setInteractiveState}
        />
        {showLoadMore && (
          <LoadMoreButton onClick={paginatedStream.loadMorePosts} />
        )}
        {paginatedStream.loadingMorePosts && <Loader />}
      </React.Fragment>
    );
  };

  return (
    <aside
      className={cn(s.root, {
        [s.open]: pendingPostVisible,
      })}
    >
      <Header
        isVisitorPostingEnabled={isVisitorPostingEnabled}
        pauseManager={pauseManager}
        scrollToTop={scrollToTop}
        unreadPosts={paginatedStream.unreadPosts}
        pendingPostVisible={pendingPostVisible}
        onClick={() => setPendingPostVisible(!pendingPostVisible)}
      />
      {isActive && !isVisitorPostingEnabled && (
        <div className={s.visitorPostingDisabledMessage}>
          Strömmen tillåter inte besökarinlägg. Detta kan ändras under strömmens
          inställningar.
        </div>
      )}
      <div className={s.scrollListWrapper}>
        <div
          ref={scrollListEl}
          onClick={pauseManager.startCursorInteraction}
          onMouseMove={handleMouseMove}
          onMouseLeave={pauseManager.stopCursorInteraction}
          className={s.scrollList}
        >
          {renderContent()}
        </div>
      </div>
    </aside>
  );
};

PendingPostStream.propTypes = {
  paginatedStream: PropTypes.shape({
    posts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    loadingInitialPosts: PropTypes.bool.isRequired,
    hasMorePosts: PropTypes.bool.isRequired,
    loadingMorePosts: PropTypes.bool.isRequired,
    unreadPosts: PropTypes.number.isRequired,
    loadMorePosts: PropTypes.func.isRequired,
    hasGuests: PropTypes.bool.isRequired,
  }),
  streamId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isVisitorPostingEnabled: PropTypes.bool.isRequired,
  pauseManager: PropTypes.shape({
    startedManuallyInteractingWithPendingPost: PropTypes.func.isRequired,
    stoppedManuallyInteractingWithPendingPost: PropTypes.func.isRequired,
    scrolledDown: PropTypes.func.isRequired,
    scrolledToTop: PropTypes.func.isRequired,
    startCursorInteraction: PropTypes.func.isRequired,
    stopCursorInteraction: PropTypes.func.isRequired,
  }),
};

export default React.memo(PendingPostStream);
