import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';

import NotificationContext from 'contexts/NotificationContext';

import Input from 'components/Input/Input';
import s from './QuickVideoEditor.module.scss';
import {
  postEditorActions,
  PostEditorContext,
} from 'components/PostEditor/PostEditorStore';
import { DirektcenterQuickVideo } from 'Types';
import uploadVideo, { trackQuickVideoUploadEvent } from 'utils/uploadVideo';
import checkUploadedVideo from 'utils/checkUploadedVideo';
import Loader from 'components/Loader/Loader';
import MediaInfo, { AudioTrack, GeneralTrack, VideoTrack } from 'mediainfo.js';

const progressMessage = (progress: number) => {
  if (progress === 0) {
    return 'Laddar upp video...';
  } else if (progress > 0 && progress <= 99) {
    return `Laddar upp video... ${progress}%`;
  } else {
    return `Bearbetar video...`;
  }
};

const getFileMetadata = async (file: File) => {
  const mediaInfo = await MediaInfo({
    locateFile: () =>
      `${process.env.REACT_APP_EDITOR_URL}/MediaInfoModule-0_3_2.wasm`,
  });

  const result = await mediaInfo.analyzeData(
    file.size,
    async (chunkSize, offset) => {
      const buffer = await file.slice(offset, offset + chunkSize).arrayBuffer();
      return new Uint8Array(buffer);
    }
  );
  mediaInfo.close();

  const generalMetadata = result.media
    ? result.media.track.find((track) => track['@type'] === 'General')
    : null;

  const videoMetadata = result.media
    ? result.media.track.find((track) => track['@type'] === 'Video')
    : null;

  const audioMetadata = result.media
    ? result.media.track.find((track) => track['@type'] === 'Audio')
    : null;

  if (generalMetadata && videoMetadata && audioMetadata) {
    const metadata = {
      general: generalMetadata as GeneralTrack,
      video: videoMetadata as VideoTrack,
      audio: audioMetadata as AudioTrack,
    };

    return metadata;
  }
};

const getVideoValidationMessage = (
  metadata:
    | {
        general: GeneralTrack;
        video: VideoTrack;
        audio: AudioTrack;
      }
    | undefined
) => {
  if (metadata?.video.Format !== 'AVC') {
    return 'Kameran måste vara inställd på "Mest kompatibel" i inställningar (Kamera > Format)';
  }

  if (metadata?.video.Duration && metadata?.video.Duration > 60) {
    return 'Videon får inte vara längre än 60 sekunder';
  }

  const approximate25Fps =
    metadata?.video.FrameRate &&
    metadata.video.FrameRate > 24 &&
    metadata.video.FrameRate < 26;

  if (!approximate25Fps) {
    return 'Videon måste vara i 25 fps';
  }

  // 1920x1080 resolution
  if (metadata?.video.Width !== 1920 || metadata?.video.Height !== 1080) {
    return 'Videon måste vara i 1920x1080 upplösning';
  }
};

const QuickVideoEditor = () => {
  const [progress, setProgress] = useState(0);

  const { state: postEditorState, dispatcher: postEditorDispatcher } =
    useContext(PostEditorContext);

  const attachment = postEditorState.postInProgress
    .attachment as DirektcenterQuickVideo;

  const [videoUrl, setVideoUrl] = useState<string | null>(
    attachment.videoFile
      ? `${process.env.REACT_APP_QUICK_VIDEO_BASE_URL}/${attachment.videoFile}`
      : null
  );

  const isUploadingQuickVideo = postEditorState.isUploadingVideo;

  const { flashNotification, flashError } = useContext(NotificationContext);

  const mergeIntoAttachment = (data: Partial<DirektcenterQuickVideo>) => {
    postEditorDispatcher({
      type: postEditorActions.MERGE_ATTACHMENT,
      payload: data,
    });
  };

  const setIsUploadingQuickVideo = useCallback(
    (value: boolean) =>
      postEditorDispatcher({
        type: postEditorActions.SET_IS_UPLOADING_VIDEO,
        payload: value,
      }),
    [postEditorDispatcher]
  );

  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    return () => {
      abortController.abort();
      setIsUploadingQuickVideo(false);
    };
  }, [setIsUploadingQuickVideo, abortController]);

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadingQuickVideo(true);

    if (e.target.files) {
      const file = e.target.files[0];

      setVideoUrl(URL.createObjectURL(file));

      const fileMetadata = await getFileMetadata(file);

      const validationMessage = getVideoValidationMessage(fileMetadata);

      if (validationMessage) {
        trackQuickVideoUploadEvent('validation-failed', file.name, {
          validationMessage,
        });
        flashError(validationMessage);
        setIsUploadingQuickVideo(false);
        setVideoUrl(null);
        return fileMetadata;
      }

      const fileName = await uploadVideo(
        file,
        flashNotification,
        (progressEvent) => {
          const percentCompleted =
            progressEvent.total &&
            Math.round((progressEvent.loaded * 100) / progressEvent.total);

          percentCompleted && setProgress(percentCompleted);
        },
        abortController
      );

      if (!fileName) {
        setIsUploadingQuickVideo(false);
        return fileMetadata;
      }

      const uploadStatus = await checkUploadedVideo(fileName, abortController);

      setIsUploadingQuickVideo(false);

      if (uploadStatus !== 'SUCCESS') {
        setVideoUrl(null);

        if (uploadStatus === 'FAILED') {
          flashError('Något gick fel vid uppladdning av video, försök igen');
        }

        return fileMetadata;
      }

      flashNotification({
        type: 'success',
        message: 'Video redo för publicering',
      });

      setVideoUrl(
        `${process.env.REACT_APP_QUICK_VIDEO_BASE_URL}/${fileName}.mp4`
      );

      mergeIntoAttachment({
        videoFile: `${fileName}.mp4`,
        posterImageFile: `${fileName}.jpg`,
      });

      return fileMetadata;
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeElapsed = Date.now();
    const metadata = await uploadFile(e);

    const uploadTime = (Date.now() - timeElapsed) / 1000;
    const videoDuration = metadata?.video.Duration || 'unknown_duration';
    const fileSize = metadata?.general.FileSize || 'unknown_size';
    const fileName =
      (e.target.files && e.target.files[0].name) || 'unknown_filename';

    trackQuickVideoUploadEvent('completed', fileName, {
      uploadTime,
      videoDuration,
      fileSize,
    });
  };

  const isVideoPreviewInteractive = !isUploadingQuickVideo;

  return (
    <React.Fragment>
      {!isUploadingQuickVideo && !attachment.videoFile && (
        <div className={cn(s.quickVideoFileInputWrapper)}>
          <input
            autoFocus
            className={s.quickVideoFileInput}
            onChange={handleUploadFile}
            type="file"
            accept=".mov, .mp4, video/quicktime, video/mp4"
            required
            id="quickVideoFileInputId"
          />
          <label
            className={s.quickVideoFileInputLabel}
            htmlFor="quickVideoFileInputId"
          >
            <div className={s.uploadFileInfoDescription}>Ladda upp video</div>
            <ul className={s.uploadFileInfoDescriptionList}>
              <li>Max 60 sekunder</li>
              <li>{'Kameraformat → "Mest kompatibel"'}</li>
              <li>HD i 1080p med 25 bilder/s</li>
            </ul>
          </label>
        </div>
      )}

      {videoUrl && (
        <div className={s.videoWrapper}>
          {!isVideoPreviewInteractive && (
            <div className={s.videoOverlay}>
              <Loader padding={false}>
                <p className={s.videoOverlayText}>
                  {progressMessage(progress)}
                </p>
              </Loader>
            </div>
          )}
          <video
            width="100%"
            height="auto"
            muted={true}
            playsInline={true}
            controls={isVideoPreviewInteractive}
            preload={'auto'}
            autoPlay={true}
            src={videoUrl}
            loop={true}
            className={s.video}
          ></video>
        </div>
      )}

      <Input
        label="Videotext"
        type="text"
        value={attachment.caption}
        onChange={(e) => mergeIntoAttachment({ caption: e.target.value })}
      />
    </React.Fragment>
  );
};

export default QuickVideoEditor;
