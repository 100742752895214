import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as DownArrowIcon } from 'img/arrow-down.svg';
import PauseIndicator from './PauseIndicator';
import PauseNotifications from './PauseNotifications';
import UserContext from 'contexts/UserContext';
import s from './Header.module.scss';
import cn from 'classnames';

const Header = ({
  pauseManager,
  scrollToTop,
  unreadPosts,
  isVisitorPostingEnabled,
  onClick,
  pendingPostVisible,
}) => {
  const { isAdminOrEditor } = useContext(UserContext);

  const pausedNotificationClick = () => {
    scrollToTop();
    pauseManager.manualResume();
  };

  const pauseIndicatorClick = () => {
    if (pauseManager.pauseState === 'MANUALLY_PAUSED') {
      pauseManager.manualResume();
    } else {
      pauseManager.manualPause();
    }
  };

  const headerTitle = isAdminOrEditor ? 'Besökarinlägg' : 'Svara på frågor';

  // Handle change in data format for /count api for backwards compatibility
  const unreadPostsString =
    typeof unreadPosts === 'number' && unreadPosts > 0
      ? ` (${unreadPosts} nya)`
      : '';

  return (
    <div className={s.header}>
      <div className={s.headerContent} onClick={onClick}>
        <h2 className={s.headerTitle}>
          {headerTitle}
          {unreadPostsString}
        </h2>
        <button className={s.toggleButton}>
          <DownArrowIcon
            className={cn(s.arrowIcon, {
              [s.isRotated]: pendingPostVisible,
            })}
          />
        </button>
      </div>
      {!isVisitorPostingEnabled && (
        <span className={s.pauseIndicator}>
          <PauseIndicator pauseState={'INACTIVE'} disabled />
        </span>
      )}
      {isVisitorPostingEnabled && (
        <React.Fragment>
          <span className={s.pauseIndicator}>
            <PauseIndicator
              pauseState={pauseManager.pauseState}
              onClick={pauseIndicatorClick}
              disabled={pauseManager.isScrolledDown}
            />
          </span>
          <PauseNotifications
            pauseState={pauseManager.pauseState}
            isScrolledDown={pauseManager.isScrolledDown}
            onClick={pausedNotificationClick}
          />
        </React.Fragment>
      )}
    </div>
  );
};

Header.propTypes = {
  pauseManager: PropTypes.shape({
    startedManuallyInteractingWithPendingPost: PropTypes.func.isRequired,
    stoppedManuallyInteractingWithPendingPost: PropTypes.func.isRequired,
    scrolledDown: PropTypes.func.isRequired,
    scrolledToTop: PropTypes.func.isRequired,
    startCursorInteraction: PropTypes.func.isRequired,
    stopCursorInteraction: PropTypes.func.isRequired,
    manualResume: PropTypes.func.isRequired,
    manualPause: PropTypes.func.isRequired,
    pauseState: PropTypes.string.isRequired,
    isScrolledDown: PropTypes.bool.isRequired,
  }),
  scrollToTop: PropTypes.func.isRequired,
  unreadPosts: PropTypes.number.isRequired,
  isVisitorPostingEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  pendingPostVisible: PropTypes.bool.isRequired,
};

export default Header;
