import { useState, useEffect, useRef } from 'react';

type UseMenuDropdownEventsReturn = {
  isOpen: boolean;
  toggleButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  menuRef: React.MutableRefObject<HTMLUListElement | null>;
  handleMenuButtonClick: () => void;
  handleMenuButtonKeydown: (event: React.KeyboardEvent<HTMLElement>) => void;
};

const useMenuDropdownEvents = (): UseMenuDropdownEventsReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleButtonRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLUListElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const setFocusToFirstItem = () => {
    window.setTimeout(() => {
      const firstListItem =
        menuRef.current?.querySelector<HTMLLIElement>('li:first-child');

      if (firstListItem) {
        firstListItem.focus();
      }
    }, 10);
  };

  const setFocusToLastItem = () => {
    window.setTimeout(() => {
      const lastListItem =
        menuRef.current?.querySelector<HTMLLIElement>('li:last-child');

      if (lastListItem) {
        lastListItem.focus();
      }
    }, 10);
  };

  const handleMenuButtonClick = () => {
    if (!isOpen) {
      setFocusToFirstItem();
    }

    setIsOpen(!isOpen);
  };

  const handleMenuButtonKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      setIsOpen(true);
      if (event.key === 'ArrowUp') {
        setFocusToLastItem();
      } else {
        setFocusToFirstItem();
        window.setTimeout(() => {
          setFocusToFirstItem();
        }, 10);
      }
    }
  };

  useEffect(() => {
    const menuButtonElement = toggleButtonRef.current;
    const menuElement = menuRef.current;
    const wrapperElement = wrapperRef.current;
    let menuItemsFirstCharacters: string[] = [];
    let menuItemElements: Element[] = [];

    const setFocusByFirstCharacter = (char: string) => {
      const getIndexFirstChars = (startIndex: number, char: string) => {
        for (let i = startIndex; i < menuItemsFirstCharacters.length; i++) {
          if (char.toLowerCase() === menuItemsFirstCharacters[i]) {
            return i;
          }
        }
        return -1;
      };

      const activeElement = document.activeElement;
      // Type narrow `activeElement`, since it's optional on document
      if (!activeElement) return;

      let startIndex = menuItemElements.indexOf(activeElement) + 1;
      if (startIndex === menuItemElements.length) {
        startIndex = 0;
      }

      let index = getIndexFirstChars(startIndex, char);
      if (index === -1) {
        index = getIndexFirstChars(0, char);
      }

      if (index > -1) {
        const item = menuItemElements[index];

        if (item instanceof HTMLElement) {
          item.focus();
        }
      }
    };

    const handleMenuKeydown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
        case ' ':
          event.preventDefault();
          const target = event.target;

          if (target instanceof HTMLElement) {
            target.click();
          }

          if (menuButtonElement) {
            menuButtonElement.focus();
          }
          break;
        case 'Escape':
          event.preventDefault();
          setIsOpen(false);
          menuButtonElement?.focus();
          break;
        case 'ArrowUp':
          event.preventDefault();
          const previousElementSibling =
            document.activeElement?.previousElementSibling;

          if (previousElementSibling instanceof HTMLElement) {
            previousElementSibling.focus();
          } else {
            setFocusToLastItem();
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          const nextElementSibling = document.activeElement?.nextElementSibling;

          if (nextElementSibling instanceof HTMLElement) {
            nextElementSibling.focus();
          } else {
            setFocusToFirstItem();
          }
          break;
        case 'Home':
          event.preventDefault();
          setFocusToFirstItem();
          break;
        case 'End':
          event.preventDefault();
          setFocusToLastItem();
          break;
        default:
          if (/^[a-zA-ZåäöÅÄÖ]$/.test(event.key)) {
            setFocusByFirstCharacter(event.key);
            // https://www.w3.org/TR/wai-aria-practices/examples/menu-button/js/PopupMenuAction.js
          }
          return;
      }
    };

    const handleWrapperLeave = (event: MouseEvent | FocusEvent) => {
      const target = event.target;

      // Close menu on click or focus on an element outside the menu wrapper
      if (target instanceof Node && !wrapperElement?.contains(target)) {
        setIsOpen(false);
      }
    };

    const handleMenuClick = () => {
      setIsOpen(false);
    };

    if (isOpen && menuElement) {
      menuElement.addEventListener('keydown', handleMenuKeydown);
      menuElement.addEventListener('click', handleMenuClick);

      document.addEventListener('click', handleWrapperLeave);
      document.addEventListener('focusin', handleWrapperLeave);

      menuItemElements = Array.from(
        menuElement.querySelectorAll<Element>('li')
      );

      menuItemsFirstCharacters = menuItemElements
        .map((listItem) => {
          const textContent = listItem.textContent;

          if (textContent) {
            return textContent.substring(0, 1).toLowerCase();
          }

          return '';
        })
        .filter(Boolean);
    }

    return () => {
      if (menuElement) {
        menuElement.removeEventListener('keydown', handleMenuKeydown);
        menuElement.removeEventListener('click', handleMenuClick);
      }

      document.removeEventListener('click', handleWrapperLeave);
      document.removeEventListener('focusin', handleWrapperLeave);
    };
  }, [isOpen]);

  return {
    isOpen,
    toggleButtonRef,
    wrapperRef,
    menuRef,
    handleMenuButtonClick,
    handleMenuButtonKeydown,
  };
};

export default useMenuDropdownEvents;
