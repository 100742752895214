import cn from 'classnames';
import DOMPurify from 'dompurify';
import s from './PostContent.module.scss';
import getPostParts from 'utils/getPostParts';
import { DirektcenterPost } from 'Types';
import AttachmentVideo from 'components/Attachment/AttachmentVideo';
import AttachmentWebpage from 'components/Attachment/AttachmentWebpage';
import AttachmentImage from 'components/Attachment/AttachmentImage';
import AttachmentQuickVideo from 'components/Attachment/AttachmentQuickVideo';

type PostContentProps = {
  post: DirektcenterPost;
  indent?: boolean;
};

const PostContent = ({ post, indent = false }: PostContentProps) => {
  const { body, heading } = getPostParts(post.body);

  const attachment = post.attachment;
  return (
    <div>
      {attachment && attachment.type === 'svt-video' && (
        <div className={s.attachment}>
          <AttachmentVideo attachment={attachment} />
        </div>
      )}
      {attachment && attachment.type === 'quick-video' && (
        <div className={s.attachment}>
          <AttachmentQuickVideo attachment={attachment} pinned={post.pinned} />
        </div>
      )}
      {heading && (
        <h2
          className={cn(s.heading, { [s.indent]: indent })}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(heading) }}
        />
      )}
      {body && (
        <div
          className={cn(s.body, { [s.indent]: indent })}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
          data-testid="postContent"
        />
      )}
      {attachment && attachment.type === 'webpage' && (
        <div className={s.attachment}>
          <AttachmentWebpage attachment={attachment} />
        </div>
      )}
      {attachment && attachment.type === 'image' && (
        <div className={s.attachment}>
          <AttachmentImage attachment={attachment} pinned={post.pinned} />
        </div>
      )}
    </div>
  );
};

export default PostContent;
