import axios from 'axios';
import { trackQuickVideoUploadEvent } from './uploadVideo';

const ATTEMPT_COUNT = 120;

const checkStatus = async (url: string) => {
  try {
    const response = await axios.head(url);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

// Future improvement: Make retriable from user interface if running out of attempts
const checkUrls = async (
  videoUrl: string,
  posterImageUrl: string,
  abortController: AbortController
) => {
  return new Promise(
    (resolve: (status: 'CANCELLED' | 'SUCCESS' | 'FAILED') => void) => {
      let attempts = 0;
      let isVideoDone = false;
      let isPosterImageDone = false;

      const interval = setInterval(async () => {
        attempts++;

        if (abortController.signal.aborted) {
          clearInterval(interval);
          resolve('CANCELLED');
          trackQuickVideoUploadEvent('cancelled-by-user', videoUrl, {
            error: 'Check uploaded video',
          });
        }

        if (!isVideoDone) {
          isVideoDone = await checkStatus(videoUrl);
        }
        if (!isPosterImageDone) {
          isPosterImageDone = await checkStatus(posterImageUrl);
        }

        if (isVideoDone && isPosterImageDone) {
          trackQuickVideoUploadEvent('checkUploadedVideo-succeded', videoUrl, {
            attempts,
          });

          clearInterval(interval);
          resolve('SUCCESS');
        }
        if (attempts >= ATTEMPT_COUNT) {
          trackQuickVideoUploadEvent('checkUploadedVideo-timeout', videoUrl, {
            attempts,
            isVideoDone,
            isPosterImageDone,
          });

          clearInterval(interval);
          resolve('FAILED');
        }
      }, 1000);
    }
  );
};

const checkUploadedVideo = async (
  file: string,
  abortController: AbortController
) => {
  const videoUrl = `${process.env.REACT_APP_QUICK_VIDEO_BASE_URL}/${file}.mp4`;
  const posterImageUrl = `${process.env.REACT_APP_QUICK_VIDEO_BASE_URL}/${file}.jpg`;

  return checkUrls(videoUrl, posterImageUrl, abortController);
};

export default checkUploadedVideo;
